"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefaultColorNames = exports.DarkColors = exports.LightColors = void 0;
/* Source: https://designsystems.prod.target.com/canvas/styles/variables-and-tokens
 * The "light" colors are actually the dark mode accent colors,
 * We use them in both light and dark mode depending on the context of use
 * Background colors are generally light in light mode and dark in dark mode
 * Border colors and accents are generally dark in light mode and light in dark mode
 */
var LightColors;
(function (LightColors) {
    LightColors["RED"] = "#ff8f8f";
    LightColors["ORANGE"] = "#d27b3d";
    LightColors["YELLOW"] = "#fef9c3";
    LightColors["LIME"] = "#d0e595";
    LightColors["GREEN"] = "#4cae75";
    LightColors["MINT"] = "#9bd4c2";
    LightColors["TEAL"] = "#93ebeb";
    LightColors["INDIGO"] = "#9b9bd9";
    LightColors["VIOLET"] = "#b98ae5";
    LightColors["PINK"] = "#e99be7";
})(LightColors || (exports.LightColors = LightColors = {}));
var DarkColors;
(function (DarkColors) {
    DarkColors["RED"] = "#cc0000";
    DarkColors["ORANGE"] = "#934b10";
    DarkColors["YELLOW"] = "#ba8b05";
    DarkColors["LIME"] = "#657b24";
    DarkColors["GREEN"] = "#256f3e";
    DarkColors["MINT"] = "#33715c";
    DarkColors["TEAL"] = "#1b7e7e";
    DarkColors["INDIGO"] = "#4646b9";
    DarkColors["VIOLET"] = "#7830c0";
    DarkColors["PINK"] = "#b72ab4";
})(DarkColors || (exports.DarkColors = DarkColors = {}));
exports.DefaultColorNames = Object.keys(LightColors);
