import { createContext, ReactNode, useContext } from 'react'
import { AnalyticsProvider } from '@praxis/component-analytics'
import { ToastProvider } from '@enterprise-ui/canvas-ui-react'
import { ApiConfig, envConfigs, ReactAppEnv } from '../apiConfig'
import { AppRelayEnvironmentProvider } from '../AppRelayEnvironmentProvider'
import { isPol } from '../utils'
import { ProgressBarProvider } from './ProgressBar'

export const MicroFrontendEnvironmentContext = createContext<ApiConfig>(envConfigs.local)

interface PropTypes {
  children: ReactNode
  environment: ReactAppEnv
}

export const MicroFrontendEnvironmentProvider = ({ children, environment }: PropTypes): JSX.Element => {
  const envConfig = envConfigs[environment]

  return (
    <MicroFrontendEnvironmentContext.Provider value={envConfig}>
      <AnalyticsProvider eventManagerConfiguration={isPol ? envConfig.pol.analytics : envConfig.analytics}>
        <ToastProvider>
          <ProgressBarProvider>
            <AppRelayEnvironmentProvider>{children}</AppRelayEnvironmentProvider>
          </ProgressBarProvider>
        </ToastProvider>
      </AnalyticsProvider>
    </MicroFrontendEnvironmentContext.Provider>
  )
}

export const useMicroFrontendEnvironment = () => useContext(MicroFrontendEnvironmentContext)
