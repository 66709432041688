import { MicroFrontend } from '@praxis/component-microfrontend'
import { ReactAppEnv, envConfigs, Main, isPol } from 'common-ui'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import initSwitcher from 'favicon-switcher/dist/index.esm'
import { useEffect } from 'react'
import { Routes, Route } from 'react-router-dom'
import { AssortmentsWithProduct } from './spark-microfrontends'
import { POLAccountSwitcher } from './POLAccountSwitcher'

const App = ({ environment }: { environment: ReactAppEnv }) => {
  useEffect(() => {
    initSwitcher()
  }, [])

  const envConfig = envConfigs[environment]

  return (
    <POLAccountSwitcher apiUrl={envConfig.apiUrl}>
      {/* Web "MFE" handles its own routing, layout, etc... */}
      <MicroFrontend environment={environment} src={(isPol ? envConfig.pol : envConfig).mfe.tileUi} />
      {/* Spark MFE workspaces */}
      <Routes>
        <Route path="/spark-mfes" element={<Main />}>
          <Route
            path="/spark-mfes/assortments-with-product"
            element={
              <AssortmentsWithProduct
                environment={environment}
                src={(isPol ? envConfig.pol : envConfig).mfe.sparkAssortmentsWithProduct}
              />
            }
          />
        </Route>
      </Routes>
    </POLAccountSwitcher>
  )
}

export default App
