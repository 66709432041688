"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDocumentTitleWithName = exports.DocumentTitleType = void 0;
var DocumentTitleType;
(function (DocumentTitleType) {
    DocumentTitleType["Dashboard"] = "Dashboard - Tile";
    DocumentTitleType["TileDetailsPage"] = "Tile Details Page - Tile";
    DocumentTitleType["FolderPage"] = "Folder Page - Tile";
    DocumentTitleType["AssortmentPage"] = "Assortment Page - Tile";
    DocumentTitleType["SearchPage"] = "Search - Tile";
    DocumentTitleType["TemplatesPage"] = "Templates - Tile";
})(DocumentTitleType || (exports.DocumentTitleType = DocumentTitleType = {}));
var getDocumentTitleWithName = function (name) {
    return name ? "".concat(name, " - Tile") : null;
};
exports.getDocumentTitleWithName = getDocumentTitleWithName;
